import { actionConstants } from '../constants';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  ec2Status: [],
  isLoadingEC2Status: false,

  ec2ToolsStatus: [],
  isLoadingEC2ToolsStatus: false,
  error: null
};

const ec2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_EC2_STATUS):
      return {
        ...state,
        isLoadingEC2Status: true
      };
    case successReqActionType(actionConstants.GET_EC2_STATUS):
      return {
        ...state,
        isLoadingEC2Status: false,
        ec2Status: action.payload
      };

    case startReqActionType(actionConstants.GET_EC2_TOOLS_STATUS):
      return {
        ...state,
        isLoadingEC2ToolsStatus: true
      };
    case successReqActionType(actionConstants.GET_EC2_TOOLS_STATUS):
      return {
        ...state,
        isLoadingEC2ToolsStatus: false,
        ec2ToolsStatus: action.payload
      };

    
    default:
      return state;
  }
};

export default ec2Reducer;
