import React from 'react';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import './index.scss';

Amplify.configure(config);
const target = document.querySelector('#root');

render(
  <StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <Provider store={store}>
          <App />
        </Provider>
      </Authenticator.Provider>
    </AmplifyProvider>
  </StrictMode>,
  target
);
