import './BatchListItem.scss';
import {
  convertUTCtoLocal,
} from 'helper/utils';
import { NavLink } from 'react-router-dom';

const BatchListItem = props => {
  const { batch } = props;

  if (!batch) {
    return <></>;
  }

  const {
    status,
    id,
    manager_id,
    email,
    notified_at,
    created_at,
    filenames,
    paid_media_ids,
    amount_paid,
    name,
    title,
    company_name,
    industry
  } = batch;

  return (
    <tr key={id} className='batch-list-item'>
      <td className='id'>
        <NavLink to={'/batch/' + id} className='link-info'>
          {id}
        </NavLink>
      </td>
      <td className='email'>
        {email}
      </td>
      <td className='owner'>
        {manager_id}
      </td>
      <td className='status'>
        <span className='text-capitalize'>
          {status}
        </span>
      </td>
      <td className='notified-at'>
        {notified_at ? convertUTCtoLocal(notified_at).format('yyyy-MM-DD HH:mm') : ''}
      </td>
      <td className='created-at'>
        {created_at ? convertUTCtoLocal(created_at).format('yyyy-MM-DD HH:mm') : ''}
      </td>
      <td className='uploaded'>
        {filenames ? filenames.length : ''}
      </td>
      <td className='selected'>
        {paid_media_ids ? paid_media_ids.length : ''}
      </td>
      <td className='paid-amount'>
        {amount_paid ? `$${amount_paid / 100}` : ''}
      </td>
      <td className='name'>
        {name}
      </td>
      <td className='title'>
        {title}
      </td>
      <td className='company'>
        {company_name}
      </td>
      <td className='industry'>
        {industry}
      </td>
    </tr>
  );
};

export { BatchListItem };
