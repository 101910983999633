import { Authenticator, Flex, Grid, useTheme } from '@aws-amplify/ui-react';

// import { Header } from "./Header";
// import { Footer } from "./Footer";
import { SignInHeader } from './SignInHeader';
import { SignInFooter } from './SignInFooter';

// const components = {
//   Header,
//   SignIn: {
//     Header: SignInHeader,
//     Footer: SignInFooter,
//   },
//   Footer,
// };

const components = {
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  }
};

export function Login() {
  const { tokens } = useTheme();

  return (
    <Grid
      style={{
        padding: '100px 0px',
        background: '#fafafa',
        minHeight: '100vh'
      }}
    >
      <Flex justifyContent='center'>
        <Authenticator components={components}>
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </Flex>
      {/* <View height="100vh">
        <Image
          src="https://images.unsplash.com/photo-1495954222046-2c427ecb546d?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1600&ixid=MnwxfDB8MXxyYW5kb218MHx8Y29tcHV0ZXJzfHx8fHx8MTYzNzE5MzE2MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=900"
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </View> */}
    </Grid>
  );
}
