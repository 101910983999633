import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import './CustomerStatistics.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import { makeDateRange } from 'helper/utils';

import moment from 'moment';
import { getStatistics } from 'redux/actions';
import { DetailViewItem } from 'components/DetailItemView';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { keyBy } from 'lodash';

const labels = {
  generated_videos: 'Video Generations',
  submitted: 'Image Submissions',
	'ai_generated': 'Image Generations',
	'auto_motions_count': 'Auto Motions',
	'parallax_motions_count': 'Parallax Motions',
	'prompt_motions_count': 'Prompt Motions',
	'count': 'Count'
};

const CustomLegend = props => {
  const { payload } = props;
  return (
    <div className='custom-legend'>
      {payload.map(item => (
        <div className='legend-item' style={{ color: item.color }}>
          <div
            className='color-box'
            style={{ background: item.color }}
          />
          {labels[item.payload.dataKey]}
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, showDay = false }) => {
  if (active && payload && payload.length) {
    let dateString = '';
    if (payload[0] && payload[0].payload && payload[0].payload.day) {
      dateString = moment(payload[0].payload.day).format('MM/DD/YYYY');
    }
    return (
      <div className='custom-tooltip'>
        {payload.map(item => (
          <p className='label'>
            {`${labels[item['dataKey']] || item['dataKey']}`}:{' '}
            <strong>{item.value}</strong>
          </p>
        ))}
        {showDay && dateString && <p className='label'>Date: {dateString}</p>}
      </div>
    );
  }

  return null;
};

const CustomerStatistics = props => {
  const { customer_id } = useParams();
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
      key: 'selection'
    }
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const startDate = moment(dateRange[0].startDate);
    const endDate = moment(dateRange[0].endDate);

    const startDateString = startDate.format('YYYY-MM-DD');
    const endDateString = endDate.format('YYYY-MM-DD');

    dispatch(getStatistics(customer_id, startDateString, endDateString, 'customers'));
  }, [customer_id, dateRange]);

  const handleSelect = ranges => {
    if (ranges && ranges.selection) {
      setDateRange([ranges.selection]);
    }
  };

  const { customerStatistics, isLoadingStatistics } = useSelector(state => ({
    customerStatistics: state.statistic.customerStatistics,
    isLoadingStatistics: state.statistic.isLoadingStatistics
  }));

  const splitByDateRange = () => {
    const startDate = moment(dateRange[0].startDate);
    let endDate = moment(dateRange[0].endDate);
    endDate = endDate.endOf('day').utc();

    const periodDateRange = makeDateRange(startDate, endDate);

    const customerStatisticsByDay = keyBy(customerStatistics, 'day');

    return periodDateRange.map(pdr => {
      const currentDateString = moment(pdr).format('YYYY-MM-DD');

      const currentStatistic = customerStatisticsByDay[currentDateString] || {
        customer_id: customer_id,
        ai_generated: 0,
        generated_videos: 0,
        auto_motions_count: 0,
        parallax_motions: {},
        prompt_motions_count: 0,
        day: currentDateString,
        submitted: 0,
        id: '',
        parallax_motions_count: 0,
        votes: {}
      };
      return currentStatistic;
    });
  };

  const statisticsByDay = splitByDateRange();

  // Get first section
  const totalSubmissions = customerStatistics.reduce((prevSum, currentValue) => {
    return prevSum + currentValue['submitted'];
  }, 0);

  const totalGenerated = customerStatistics.reduce((prevSum, currentValue) => {
    return prevSum + currentValue['generated_videos'];
  }, 0);

  const imageGeneated = customerStatistics.reduce((prevSum, currentValue) => {
    return prevSum + currentValue['ai_generated'];
  }, 0);

  const totalCredits = totalGenerated * 20 + imageGeneated * 4;

  // Get parallax motions
	const parallaxMotions = {};
	customerStatistics.forEach(byDayItems => {
		const { parallax_motions } = byDayItems;
		Object.keys(parallax_motions).map(motion_key => {
			if (parallaxMotions[motion_key]) {
				parallaxMotions[motion_key] += parallax_motions[motion_key] || 0
			} else {
				parallaxMotions[motion_key] = parallax_motions[motion_key] || 0
			}
		})
	});
	const parallaxList = Object.keys(parallaxMotions).map(motion_key => ({
		motion_key,
		count: parallaxMotions[motion_key]
	}));

	const ratings = {};
	customerStatistics.forEach(byDayItems => {
		const { votes } = byDayItems;
		Object.keys(votes).map(vote_key => {
			if (ratings[vote_key]) {
				ratings[vote_key] += votes[vote_key] || 0
			} else {
				ratings[vote_key] = votes[vote_key] || 0
			}
		})
	});
	const ratingList = Object.keys(ratings).map(vote_key => ({
		vote_key,
		count: ratings[vote_key]
	}));

  return (
    <div className='user-statistics-page'>
      <div className='container-fluid'>
        <div className='page-header'>
          <h1>Statistics - {customer_id} {isLoadingStatistics && (<i className='fas fa-redo fa-spin' />)}</h1>
          <Button
            variant='primary'
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3'>
            <div className='date-range-picker-wrapper'>
              <DateRangePicker ranges={dateRange} onChange={handleSelect} />
            </div>
          </div>
          <div className='col-lg-6 mb-3'>
            <div className='row'>
              <div className='col-md-3'>
                <DetailViewItem title='Submitted' value={totalSubmissions} />
              </div>
              <div className='col-md-3'>
                <DetailViewItem
                  title='Video Generated'
                  value={totalGenerated}
                />
              </div>
              <div className='col-md-3'>
                <DetailViewItem title='Image Generated' value={imageGeneated} />
              </div>
              <div className='col-md-3'>
                <DetailViewItem title='Credits' value={totalCredits} />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Video Generations, Image Submissions By Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart width={800} height={300} data={statisticsByDay}>
                  <Line
                    type='monotone'
                    dataKey='generated_videos'
                    stroke='#1fe074'
                  />
                  <Line type='monotone' dataKey='submitted' stroke='#0b1d78' />
                  <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                  <XAxis dataKey='day' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
						<h5>Image Generations By Date</h5>
						<div className='chart-wrapper'>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart width={800} height={300} data={statisticsByDay}>
									<Line type="monotone" dataKey="ai_generated" stroke="#0b1d78" />
									<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
									<XAxis dataKey="day" />
									<YAxis />
									<Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
								</LineChart>
							</ResponsiveContainer>
						</div>
					</div>
        </div>
        <div className='row'>
					<div className='col-lg-6 mb-3 mt-3'>
						<h5>Auto motion vs Parallax motion vs Prompt motion</h5>
						<div className='chart-wrapper'>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart width={800} height={300} data={statisticsByDay}>
									<Bar dataKey="auto_motions_count" fill="#0b1d78"/>
									<Bar dataKey="parallax_motions_count" fill="#00a9b5" />
									<Bar dataKey="prompt_motions_count" fill="#1fe074" />
									<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
									<XAxis dataKey="day" />
									<YAxis />
									<Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div className='col-lg-6 mb-3 mt-3'>
						<h5>Parallax motion By Camera Path</h5>
						<div className='chart-wrapper'>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart width={800} height={300} data={parallaxList}>
									<Bar dataKey="count" fill="#00a9b5" />
									<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
									<XAxis dataKey="motion_key" />
									<YAxis />
									<Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-6 mb-3 mt-3'>
						<h5>Total ({ratingList.length})Votes</h5>
						<div className='chart-wrapper'>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart width={800} height={300} data={ratingList}>
									<Bar dataKey="count" fill="#0b1d78" activeBar={<Rectangle fill="pink" stroke="blue" />}/>
									<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
									<XAxis dataKey="vote_key" />
									<YAxis />
									<Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
      </div>
    </div>
  );
};

export { CustomerStatistics };
