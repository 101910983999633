import { useState, useEffect } from 'react';
import './UserDetailPage.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Alert,
  Modal,
  Dropdown,
  Badge
} from 'react-bootstrap';
import {
  updateUserCredit,
  createOrUpdateUser,
  deleteUser,
  disableUser,
  enableUser,
  updateUserDiscount,
  addUserToGroup,
  removeUserFromGroup
} from 'redux/actions';
import { getAttributeFromUser, getUserAttribute } from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import { DISCOUNT_OPTIONS, VIMMERSE_CUSTOMER_ID } from 'helper/constants';

const UserDetailPage = props => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [givenName, setGivenName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [preferredUsername, setPreferredUsername] = useState('');
  // const [apiKey, setApiKey] = useState('');

  const [phone, setPhone] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');

  const [showUpdateCredit, setShowUpdateCredit] = useState(false);
  const [newCreditAmount, setNewCreditAmount] = useState(0);
  const [showSetDiscount, setShowSetDiscount] = useState(false);
  const [discount, setDiscount] = useState(DISCOUNT_OPTIONS[0]);

  useEffect(() => {
    if (user_id && user_id !== 'new') {
      if (!Object.values(props.allUsers).length) {
      } else {
        const currentUser = props.allUsers[user_id];
        setUsername(currentUser['Username']);

        const Attributes = currentUser.Attributes || currentUser.UserAttributes;

        setGivenName(getUserAttribute(Attributes, 'given_name') || '');
        setPreferredUsername(
          getUserAttribute(Attributes, 'preferred_username') ||
            currentUser['Username']
        );

        setEmail(getUserAttribute(Attributes, 'email') || '');

        setPhone(getUserAttribute(Attributes, 'phone_number') || '');
        setFamilyName(getUserAttribute(Attributes, 'family_name') || '');
        setWebsite(getUserAttribute(Attributes, 'website') || '');
        setDescription(getUserAttribute(Attributes, 'custom:bio') || '');
        setDiscount(
          DISCOUNT_OPTIONS.find(
            co => co.value === getUserAttribute(Attributes, 'custom:discount')
          ) || null
        );
        // const currentUserApiKey = props.allUserApiKeys[currentUser['Username']]?.key || '';
        // if (currentUserApiKey) {
        //   setApiKey(currentUserApiKey);
        // }
      }
    }
  }, [user_id, props.allUsers, dispatch]);

  const currentUser = props.allUsers[user_id];
  const developerUser = props.Developer[user_id];
  const staffUser = props.Staff[user_id];

  const handleSubmit = () => {
    if (username === '') {
      NotificationManager.error('Username is required', 'Submission Error');
      return;
    }
    if (preferredUsername === '') {
      NotificationManager.error('Handle is required', 'Submission Error');
      return;
    }
    if (email === '') {
      NotificationManager.error('Email is required', 'Submission Error');
      return;
    }

    let payload = {
      username,
      email,
      given_name: givenName,
      preferred_username: preferredUsername,
      phone_number: phone,
      family_name: familyName,
      website,
      custom_bio: description
    };

    dispatch(createOrUpdateUser(payload));
    // NotificationManager.success('You are succeed to submit your user', 'Submission Success');
  };

  const onUpdateCredit = () => {
    if (newCreditAmount) {
      const payload = {
        username,
        amount: newCreditAmount
      };
      dispatch(updateUserCredit(payload));
      setShowUpdateCredit(false);
    }
  };

  const onUpdateDiscount = () => {
    if (discount) {
      const payload = {
        username,
        discount: discount.value
      };
      dispatch(updateUserDiscount(payload));
      setShowSetDiscount(false);
    }
  };

  const onDeleteUser = () => {
    dispatch(deleteUser(user_id));
    navigate('/users');
  };

  const onDisableUser = () => {
    dispatch(disableUser(user_id));
    navigate('/users');
  };

  const onEnablesUser = () => {
    dispatch(enableUser(user_id));
    navigate('/users');
  };

  // const onGenerateApiKey = async () => {
  //   const response = await generateApiKey(user_id);
  //   setApiKey(response);
  // }

  const onAddAccountToDeveloper = () => {
    dispatch(addUserToGroup(user_id, 'Developer'));
  };

  const onRemoveAccountFromDeveloper = () => {
    dispatch(removeUserFromGroup(user_id, 'Developer'));
  };

  const onAddAccountToStaff = () => {
    dispatch(addUserToGroup(user_id, 'Staff'));
  };

  const onRemoveAccountFromStaff = () => {
    dispatch(removeUserFromGroup(user_id, 'Staff'));
  };

  const customersOptions = Object.values(props.allCustomers).map(co => ({
    value: co.id,
    label: co.name,
    customer_id: co.customer_id,
    customer_key: co.key || ''
  }));

  const onViewContents = () => {
    const selectedCustomer = customersOptions.find(
      co => co.customer_id === VIMMERSE_CUSTOMER_ID
    );
    if (selectedCustomer) {
      localStorage.setItem('customer', JSON.stringify(selectedCustomer));
      navigate(`/media?created_by=${user_id}`);
    }
  };

  return (
    <div className='user-detail-page'>
      <div className='page-header'>
        <h1>
          {user_id === 'new' ? 'New User' : user_id}
          {!(currentUser && currentUser.Enabled) ? (
            <Badge className='bg-secondary ms-2'>Disabled</Badge>
          ) : (
            ''
          )}
          {currentUser && developerUser ? (
            <Badge className='bg-primary ms-2'>Developer</Badge>
          ) : (
            ''
          )}
          {currentUser && staffUser ? (
            <Badge className='bg-info ms-2'>Staff</Badge>
          ) : (
            ''
          )}
        </h1>
        <div className='actions'>
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='statistics'
                as={Link}
                to={`/statistics/users/${user_id}`}
              >
                Statistics
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey='view-contents' onClick={onViewContents}>
                View Contents
              </Dropdown.Item>
              <Dropdown.Divider />
              {currentUser && currentUser.Enabled ? (
                <Dropdown.Item eventKey='disable-user' onClick={onDisableUser}>
                  Disable user access
                </Dropdown.Item>
              ) : (
                <Dropdown.Item eventKey='enable-user' onClick={onEnablesUser}>
                  Enable user access
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              {/* <Dropdown.Item eventKey='generate-api-key' onClick={onGenerateApiKey}>
                {
                  apiKey ? 'Regenerate API Key' : 'Generate API Key'
                }
                
              </Dropdown.Item>
              <Dropdown.Divider /> */}
              <Dropdown.Item eventKey='delete' onClick={onDeleteUser}>
                Delete
              </Dropdown.Item>
              <Dropdown.Divider />
              {developerUser ? (
                <Dropdown.Item
                  eventKey='remove-developer'
                  onClick={onRemoveAccountFromDeveloper}
                >
                  Remove account from Developer
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey='add-developer'
                  onClick={onAddAccountToDeveloper}
                >
                  Add account to Developer
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              {staffUser ? (
                <Dropdown.Item
                  eventKey='remove-staff'
                  onClick={onRemoveAccountFromStaff}
                >
                  Remove account from Staff
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey='add-staff'
                  onClick={onAddAccountToStaff}
                >
                  Add account to Staff
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='container'>
        <h3>Required Fields</h3>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Username *</FormLabel>
              <FormControl
                type='text'
                value={username}
                disabled={user_id && user_id !== 'new'}
                required
                placeholder='Username'
                onChange={e => setUsername(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Email *</FormLabel>
              <FormControl
                type='email'
                value={email}
                required
                placeholder='Email'
                onChange={e => setEmail(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Handle *</FormLabel>
              <FormControl
                type='text'
                value={preferredUsername}
                required
                placeholder='Handle'
                onChange={e => setPreferredUsername(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>

        <h3>Optional Fields</h3>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>First Name</FormLabel>
              <FormControl
                type='text'
                value={givenName}
                placeholder='First name'
                onChange={e => setGivenName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Last Name</FormLabel>
              <FormControl
                type='text'
                value={familyName}
                placeholder='Last Name'
                onChange={e => setFamilyName(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          {/* <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Website</FormLabel>
              <FormControl
                type='text'
                value={website}
                placeholder='Website URL'
                onChange={e =>setWebsite(e.target.value)}
              />
            </FormGroup>
          </div> */}
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Bio</FormLabel>
              <FormControl
                type='text'
                value={description}
                placeholder='Bio'
                onChange={e => setDescription(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Button
              onClick={handleSubmit}
              variant='primary'
              disabled={username === ''}
            >
              Save
            </Button>
          </div>
        </div>
        <hr></hr>
        {currentUser && (
          <div className='row'>
            <div className='col-md-6'>
              <h5>Credit</h5>
              {developerUser ? (
                <div className='row'>
                  <div className='col-md-6'>
                    <FormGroup>
                      <p>Developer</p>
                    </FormGroup>
                  </div>
                  <div className='col-md-6'></div>
                </div>
              ) : (
                currentUser && (
                  <div className='row'>
                    <div className='col-md-6'>
                      <FormGroup>
                        <p className='fw-bold'>
                          {getAttributeFromUser(currentUser, 'custom:credit')}
                        </p>
                      </FormGroup>
                    </div>
                    <div className='col-md-6'>
                      <Button
                        variant='primary'
                        onClick={() => setShowUpdateCredit(true)}
                      >
                        Update Credit
                      </Button>
                    </div>
                  </div>
                )
              )}
            </div>
            {/* <div className='col-md-6'>
                <h5>Discount</h5>
                {
                  currentUser && (
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormGroup>
                          <p className='fw-bold'>
                            {discount && discount.label}
                          </p>
                        </FormGroup>
                      </div>
                      <div className='col-md-6'>
                        <Button variant='primary' onClick={() => setShowSetDiscount(true)}>
                          Set Discount
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div> */}
          </div>
        )}
        {/* {
          apiKey && (
            <>
              <h5>API Key</h5>
              <div className='row'>
                <div className='col-md-6'>
                  <FormGroup>
                    <FormControl
                      type='text'
                      value={apiKey}
                      placeholder='API KEY'
                      readOnly={true}
                    />
                  </FormGroup>
                </div>
              </div>
            </>
          )
        } */}

        {/* <div className='row'>
          <div className='col'>
            <FormGroup>
              <FormLabel>Phone number</FormLabel>
              <FormControl
                type='text'
                value={phone}
                placeholder='+1 (234) 567-8901'
                onChange={e =>setPhone(e.target.value)}
              />
            </FormGroup>
          </div>
        </div> */}
        <div className='mb-3'>
          <Alert variant='info'>
            For new users, temporary password is VimmerseUser2023
          </Alert>
        </div>
      </div>
      {showUpdateCredit && (
        <Modal
          show={true}
          onHide={() => setShowUpdateCredit(false)}
          backdrop='static'
          size='md'
        >
          <Modal.Header>Add/Remove Credit</Modal.Header>
          <Modal.Body>
            <FormGroup className='mb-3'>
              <FormLabel>How many credit do you want to add/remove?</FormLabel>
              <FormControl
                type='number'
                value={newCreditAmount}
                placeholder='Please input new credit amount'
                onChange={e => setNewCreditAmount(e.target.value)}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={onUpdateCredit}>
              Save
            </Button>
            <Button
              variant='secondary'
              onClick={() => setShowUpdateCredit(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showSetDiscount && (
        <Modal
          show={true}
          onHide={() => setShowSetDiscount(false)}
          backdrop='static'
          size='md'
        >
          <Modal.Header>Set Discount</Modal.Header>
          <Modal.Body>
            <FormGroup className='mb-3'>
              <FormLabel>Discount Options</FormLabel>
              <Select
                options={DISCOUNT_OPTIONS}
                value={discount}
                onChange={setDiscount}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={onUpdateDiscount}>
              Save
            </Button>
            <Button
              variant='secondary'
              onClick={() => setShowSetDiscount(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { users, customers } = state;
  const { allUsers, Developer, Staff, allUserApiKeys } = users;
  const { allCustomers } = customers;
  return {
    allCustomers,
    allUsers,
    Developer,
    Staff,
    allUserApiKeys
  };
}

const connectedObject = connect(mapStateToProps)(UserDetailPage);
export { connectedObject as UserDetailPage };
