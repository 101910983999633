/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import {
  getEc2Status,
  getQueueSizes,
  getTools,
  purgeQueue
} from 'redux/actions';
import { Button } from 'react-bootstrap';
import { QueueSizeRender } from './QueueSizeRender';
import { NotificationManager } from 'react-notifications';

const SQSRender = props => {
  const dispatch = useDispatch();

  const { isLoadingTools, isLoadingQueueSizes, isLoadingEC2Status } =
    useSelector(state => ({
      isLoadingTools: state.tools.isLoadingTools,
      isLoadingQueueSizes: state.queue.isLoadingQueueSizes,
      isLoadingEC2Status: state.ec2.isLoadingEC2Status
    }));

  const onRefresh = () => {
    if (!isLoadingTools) {
      dispatch(getTools());
    }

    if (!isLoadingQueueSizes) {
      dispatch(getQueueSizes());
    }

    if (!isLoadingEC2Status) {
      dispatch(getEc2Status());
    }
  };

  const onPurgeQueue = type => {
    if (window.confirm('Are you sure to purge messages from the queue?')) {
      dispatch(
        purgeQueue(type, () => {
          NotificationManager.success('Purged successfully.', 'Queue Purged');
          onRefresh();
        })
      );
    }
  };

  return (
    <div className='sqs-render'>
      <div className='queue-size-render-box'>
        <div className='box-header'>
          <h5>Process Queue</h5>
          <Button
            variant='danger'
            size='sm'
            onClick={() => onPurgeQueue('process_queue')}
          >
            Purge
          </Button>
        </div>
        <div className='box-body'>
          <QueueSizeRender
            type='process_queue'
            detail='approximate_number_of_messages'
          />
          <QueueSizeRender
            type='process_queue'
            detail='approximate_number_of_messages_delayed'
          />
        </div>
      </div>

      <div className='queue-size-render-box'>
        <div className='box-header'>
          <h5>Batch Queue</h5>
          <Button
            variant='danger'
            size='sm'
            onClick={() => onPurgeQueue('batch_queue')}
          >
            Purge
          </Button>
        </div>
        <div className='box-body'>
          <QueueSizeRender
            type='batch_queue'
            detail='approximate_number_of_messages'
          />
          <QueueSizeRender
            type='batch_queue'
            detail='approximate_number_of_messages_delayed'
          />
        </div>
      </div>

      <div className='queue-size-render-box'>
        <div className='box-header'>
          <h5>Generate AI Queue</h5>
          <Button
            variant='danger'
            size='sm'
            onClick={() => onPurgeQueue('generate_ai_queue')}
          >
            Purge
          </Button>
        </div>
        <div className='box-body'>
          <QueueSizeRender
            type='generate_ai_queue'
            detail='approximate_number_of_messages'
          />
          <QueueSizeRender
            type='generate_ai_queue'
            detail='approximate_number_of_messages_delayed'
          />
        </div>
      </div>
    </div>
  );
};

export { SQSRender };
