import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './BatchList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormGroup, FormLabel, Button, Table } from 'react-bootstrap';
import { BATCH_STATUS_OPTIONS } from 'helper/constants';
import { paginatedListBatches } from 'redux/actions';
import { CustomerSelect } from 'components/CustomerSelect';
import Select from 'react-select';
import { VimmersePaginationBar } from 'components/VimmersePaginationBar';
import { BatchListItem } from 'components/BatchListItem';

const BatchList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { paginatedBatches, lastEvaluatedKeys, totalCount } = useSelector(
    state => ({
      paginatedBatches: state.batch.paginatedBatches,
      lastEvaluatedKeys: state.batch.lastEvaluatedKeys,
      totalCount: state.batch.totalCount
    })
  );

  const [statusFilter, setStatusFilter] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState('');
  const [perPage, setPerPage] = useState(40);
  const [page, setPage] = useState(1);
  const [customer, setCustomer] = useState(null);

  const searchParams = useSearchParams();

  useEffect(() => {
    setCreatedByFilter(searchParams[0].get('created_by', ''));

    const statusValue = searchParams[0].get('status') || '';
    const statusValues = statusValue.split(',');
    const selectedStatus = BATCH_STATUS_OPTIONS.filter(pso =>
      statusValues.includes(pso.value)
    );
    setStatusFilter(selectedStatus);

    const queryString = searchParams[0].toString();
    onStartSearch(queryString, true);
  }, []);

  const onStartSearch = (queryString, reset = false) => {
    dispatch(paginatedListBatches(queryString, reset));
  };

  const onSearch = (reset = false) => {
    if (reset) {
      setPage(1);
    }

    const params = new URLSearchParams({
      manager_id: createdByFilter || '',
      status: statusFilter.map(obj => obj.value).join(','),
      last_evaluated_key: reset ? '' : JSON.stringify(lastEvaluatedKeys)
    });
    const queryString = params.toString();
    onStartSearch(queryString, reset);
    navigate(`/batch?${queryString}`);
  };

  const onChangeRowsPerPage = newPerPage => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const onChangePage = page => {
    const required_count = page * perPage;
    if (required_count > paginatedBatches.length) {
      if (lastEvaluatedKeys) {
        onSearch();
      } else {
        return;
      }
    }

    setPage(page);
  };

  let totalPages = 0;

  if (!lastEvaluatedKeys) {
    if (paginatedBatches.length) {
      totalPages = Math.ceil(totalCount / perPage);
    }
  }

  const displayingBatches = paginatedBatches.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <div className='sessions-list-page'>
      <div className='page-header'>
        <h1>Batch List</h1>
      </div>
      <div className='contents'>
        <div className='row align-items-end pb-2'>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Customer ID</FormLabel>
              <CustomerSelect
                onChangeCustomer={setCustomer}
                customer={customer}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Created By</FormLabel>
              <FormControl
                type='text'
                value={createdByFilter}
                placeholder='Please input creator to search'
                onChange={e => setCreatedByFilter(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Status</FormLabel>
              <Select
                isMulti
                options={BATCH_STATUS_OPTIONS}
                value={statusFilter}
                onChange={setStatusFilter}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <Button onClick={() => onSearch(true)}>Search</Button>
          </div>
        </div>
        <div className='mt-2'>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
          <Table striped responsive>
            <thead>
              <td>ID</td>
              <td>Email</td>
              <td>Owner</td>
              <td>Status</td>
              <td>Notified At</td>
              <td>Created At</td>
              <td>Uploaded</td>
              <td>Selected</td>
              <td>Paid Amount</td>
              <td>Name</td>
              <td>Title</td>
              <td>Company</td>
              <td>Industry</td>
            </thead>
            <tbody>
              {displayingBatches.map(pm => (
                <BatchListItem batch={pm} key={pm.id} />
              ))}
            </tbody>
          </Table>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
        </div>
      </div>
    </div>
  );
};

export { BatchList };
