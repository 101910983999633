/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
  SidebarHeader
} from 'react-pro-sidebar';
import {
  ENV_OPTIONS
} from 'helper/constants';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import './sidebar.scss';

const Sidebar = props => {
  const [env, setEnv] = useState(ENV_OPTIONS[0]);
  
  const [windowWidth, setWindowWidth] = useState(1024);
  const { collapsed, setCollapsed } = props;

  useEffect(() => {
    const envString = localStorage.getItem('myEnv');
    if (envString) {
      const envValue = JSON.parse(envString);
      setEnv(envValue);
    }
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (windowWidth < 1024) {
      setCollapsed(true);
    }
  }, [windowWidth]);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const onChangeEnvironment = value => {
    localStorage.setItem('myEnv', JSON.stringify(value));
    setEnv(value);
    document.location.reload();
  };

  return (
    <>
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div className='header-wrapper d-flex'>
            <Button
              variant='outline-light'
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              <i className='fas fa-bars' />
            </Button>
            <h3>Vimmerse</h3>
          </div>
        </SidebarHeader>
        <SidebarHeader>
          <Select
            className='env-selector'
            classNamePrefix='env-select'
            onChange={onChangeEnvironment}
            options={ENV_OPTIONS}
            value={env}
          />
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape='square'>
            <MenuItem icon={<i className='fas fa-dashboard' />}>
              Dashboard
              <Link to='/' />
            </MenuItem>
            <MenuItem icon={<i className='fas fa-camera' />}>
              Medias
              <Link to='/media' />
            </MenuItem>
            <MenuItem icon={<i className='fas fa-inbox' />}>
              Batches
              <Link to='/batch' />
            </MenuItem>
            <hr />
            <MenuItem icon={<i className='fas fa-users' />}>
              Vimmerse Users
              <Link to='/users' />
            </MenuItem>
            <MenuItem icon={<i className='fas fa-user-tie' />}>
              Customers
              <Link to='/customers' />
            </MenuItem>
            <hr />
            <MenuItem icon={<i className='fas fa-tools' />}>
              Tools
              <Link to='/tools' />
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter className='sidebar-footer'>
          <Button variant='danger' onClick={signOut}>
            <i className='fas fa-right-from-bracket' />
          </Button>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};
export default Sidebar;
