/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ToolsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getTools } from 'redux/actions';
import { Button } from 'react-bootstrap';
import { ToolsRender } from 'containers/DashboardPage/ToolsRender';

const ToolsList = props => {
  const dispatch = useDispatch();

  const { isLoadingTools } = useSelector(state => ({
    isLoadingTools: state.tools.isLoadingTools
  }));

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    if (!isLoadingTools) {
      dispatch(getTools());
    }
  };

  return (
    <div className='tools-list-page'>
      <div className='page-header'>
        <h1>Tools</h1>
        <div>
          <Button
            variant='primary'
            className='me-2'
            onClick={onRefresh}
            disabled={isLoadingTools}
          >
            <i className={`fas fa-redo ${isLoadingTools ? 'fa-spin' : ''}`} />{' '}
            {!isLoadingTools ? 'Refresh' : 'Loading'}
          </Button>
          {/* <Link to={'/tools/new'} className='btn btn-primary'>
            <i className='fas fa-plus' /> New Tool
          </Link> */}
        </div>
      </div>
      <div className='contents'>
        <ToolsRender EnableRowClick />
      </div>
    </div>
  );
};

export { ToolsList };
