import moment from 'moment';
import {
  ANIMATEAI_QUANTITY,
  AI_TYPES,
  BATCH_STATUS_FAIL,
  BATCH_STATUS_PROCESSING,
  BATCH_STATUS_SUCCESS,
  DEFAULT_POSE_PRESET,
  PROCESSING_STATUS_SUCCESS,
  PUBLIC_STATUS_PRIVATE,
  PUBLIC_STATUS_PUBLIC,
  PUBLIC_STATUS_UNLISTED,
  STATUS_STRING_MAP,
  SUPPORTED_AUDIO_FORMATS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
  VIMMERSE_CUSTOMER_ID
} from './constants';
import { NotificationManager } from 'react-notifications';

export const convertUTCtoLocal = dateString =>
  moment(moment.utc(dateString).toDate());
export const convertSecToMins = secs => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  // return hours+':'+minutes+':'+seconds;
  return minutes + ':' + seconds;
};

export const download = (url, filename) => {
  fetch(url)
    .then(response =>
      response.status === 200 ? response.blob() : Promise.reject()
    )
    .then(blob => {
      if (blob.size) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    })
    .catch(console.error);
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const getStatusText = status => {
  return STATUS_STRING_MAP[status];
};

export const getPublicStatusClassName = publicStatus => {
  switch (publicStatus) {
    case PUBLIC_STATUS_PRIVATE:
      return 'danger';
    case PUBLIC_STATUS_PUBLIC:
      return 'success';
    case PUBLIC_STATUS_UNLISTED:
    default:
      return 'primary';
  }
};

export const getEnvironment = () => {
  const envString = localStorage.getItem('myEnv');
  let envName = 'prod';
  if (envString) {
    const envValue = JSON.parse(envString);
    envName = envValue.value;
  }
  if (envName === 'prod') {
    return {
      ENVIRONMENT_NAME: "Production",
      API_URL: process.env.REACT_APP_API_URL,
      CONTENT_URL: process.env.REACT_APP_CONTENT_URL
    };
  }
  return {
    ENVIRONMENT_NAME: "Development",
    API_URL: process.env.REACT_APP_API_URL_DEV,
    CONTENT_URL: process.env.REACT_APP_CONTENT_URL_DEV
  };
};

export const getUserAttribute = (attributes, attrName) => {
  const obj = attributes.find(obj => obj.Name === attrName);
  return obj?.Value || null;
};

export const getAttributeFromUser = (user, attr) => {
  const Attributes = user.Attributes || user.UserAttributes;
  const dataObj = Attributes.find(obj => obj.Name === attr);
  return dataObj?.Value || '';
};

export const generateRandomString = length => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
};

export const autoDetectFileType = filenames => {
  const fileMap = {};
  const bgRegex = /b([0-9])+_/g;

  filenames.forEach(f => {
    const baseFileName = f.toLowerCase();
    const splitName = baseFileName.split('.');
    const ext = splitName[splitName.length - 1];

    let fileType = 'Texture';

    if (SUPPORTED_AUDIO_FORMATS.includes(ext)) {
      fileType = 'Audio';
    } else if (['json', 'bit', 'txt'].includes(ext)) {
      fileType = 'Metadata';
    } else if (ext === 'csv') {
      fileType = 'PoseTrace';
    } else if (ext === 'cfg') {
      fileType = 'Config';
    } else if (
      !SUPPORTED_IMAGE_FORMATS.includes(ext) &&
      !SUPPORTED_VIDEO_FORMATS.includes(ext)
    ) {
      fileType = 'Unknown';
    } else {
      if (baseFileName.includes('mask') || baseFileName.includes('_m.')) {
        fileType = 'Mask';
      } else if (
        baseFileName.includes('erp') ||
        baseFileName.includes('erp360') ||
        baseFileName.includes('erp_360')
      ) {
        fileType = 'BG Texture ERP360';
      } else if (
        baseFileName.includes('erp180') ||
        baseFileName.includes('erp_180')
      ) {
        fileType = 'BG Texture ERP180';
      } else if (
        baseFileName.includes('background') ||
        baseFileName.includes('bg_') ||
        baseFileName.match(bgRegex)
      ) {
        if (baseFileName.includes('depth') || baseFileName.includes('_d.')) {
          fileType = 'BG Depth';
        } else {
          fileType = 'BG Texture';
        }
      } else if (
        baseFileName.includes('depth') ||
        baseFileName.includes('_d.') ||
        baseFileName.includes('_geo')
      ) {
        fileType = 'Depth';
      } else if (
        baseFileName.includes('sbs') &&
        (baseFileName.includes('background') || baseFileName.match(bgRegex))
      ) {
        fileType = 'BG SBS Texture Depth';
      } else if (
        baseFileName.includes('sbs') ||
        baseFileName.includes('_3d.') ||
        baseFileName.includes('_pac')
      ) {
        fileType = 'SBS Texture Depth';
      } else {
        fileType = 'Texture';
      }
    }

    fileMap[baseFileName] = fileType;
  });
  return fileMap;
};

export const replaceSpecialCharNaming = filename => {
  const regex = /[^a-zA-Z0-9_.]/g;
  return filename.replaceAll(regex, '_');
};

export const download3dContent = content => {
  const { stream_type } = content;
  const contentId = content.id;

  const contentURL = getEnvironment().CONTENT_URL + contentId;

  if (stream_type === 'png') {
    let filename = contentId + '_3d.png';
    download(contentURL + '/' + filename, filename);

    filename = contentId + '_vim_metadata.bit';
    download(contentURL + '/' + filename, filename);

    filename = contentId + '_audio.aac';
    download(contentURL + '/' + filename, filename);

    filename = contentId + '_audio.mp3';
    download(contentURL + '/' + filename, filename);
  } else {
    let threeDVideo = contentURL + '/' + contentId + '_3d.mp4';
    download(threeDVideo, contentId + '_3d.mp4');
  }
};

export const downloadBullet = content => {
  const contentId = content.id;
  const contentURL = getEnvironment().CONTENT_URL + contentId;

  const mp4Url = contentURL + '/bullet/' + contentId + '.mp4';
  download(mp4Url, contentId + '.mp4');

  const gifUrl = contentURL + '/bullet/' + contentId + '.gif';
  download(gifUrl, contentId + '.gif');
};

export const downloadSubmittedData = content => {
  const contentId = content.id;
  const contentURL = getEnvironment().CONTENT_URL + contentId;

  const dataURL = contentURL + '/' + contentId + '_submittedData.zip';
  download(dataURL, contentId + '_submittedData.zip');
};

export const downloadMivSource = content => {
  const contentId = content.id;
  const contentURL = getEnvironment().CONTENT_URL + contentId;

  const mivZip = contentURL + '/' + contentId + '_miv.zip';
  download(mivZip, contentId + '_sourceViews.zip');
  const sourceViewZip = contentURL + '/' + contentId + '_sourceViews.zip';
  download(sourceViewZip, contentId + '_sourceViews.zip');
};

export const downloadLog = content => {
  const logURL = getEnvironment().CONTENT_URL + content.id + '/processing.log';
  download(logURL, content.id + '.log');
};

export const copyToClipboard = text => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      NotificationManager.success('Copied to your clipboard', 'Success');
    })
    .catch(error => {
      console.error(`Error copying text: ${error}`);
    });
};

export const getCurrentCustomerId = () => {
  const customerString = localStorage.getItem('customer');
  const customerValue = customerString ? JSON.parse(customerString) : null;
  if (!customerValue) {
    return VIMMERSE_CUSTOMER_ID;
  }
  return customerValue.customer_id;
};

export const getPosePresetsFromOldBatch = (batchObject) => {
  const {motion_types, motion_params} = batchObject
  const motionTypes = motion_types.split(',');
  let posePresets = [];
  motionTypes.forEach(motionType => {
    let posePreset = {};
    if (AI_TYPES.includes(motionType)) {
      posePreset['MotionType'] = motionType;
      posePreset['Params'] = motion_params;
      if (motionType === 'Prompt')
          posePreset['Params'] = 'Prompt=|' + posePreset['Params'];
    } else {
      posePreset['MotionType'] = 'Parallax'
      posePreset['Params'] = 'CameraPath=' + motionType + '|' + motion_params
    }
    posePresets.push(posePreset)
  })
  return posePresets;
};

export const getMotionTypesFromBatch = (batchObject) => {
  const {pose_presets, motion_types} = batchObject
  let motionTypes = []
  if (pose_presets) {
    pose_presets.forEach(posePreset => {
      let motionType = posePreset['MotionType'];
      let paramsArgs = posePreset['Params'].split('|');
      let cameraPath = 'StepIn'
      let quantity = 1
      for (const param of paramsArgs) {
        const [key, value] = param.split('=');  
        switch (key) {
          case 'CameraPath':
            cameraPath = value
          case 'Quantity':
            quantity = value
        }
      }

      if (motionType === 'Parallax') {
        motionTypes.push(cameraPath);
      } else if (AI_TYPES.includes(motionType)) {
        if (quantity === 1) {
          motionTypes.push(motionType)
        } else {
          for (let i = 1; i <= quantity; i++){
            motionTypes.push(motionType + i.toString())
          }          
        }
      }
    });
  } else {
    motionTypes = motion_types.split(',');
    if (ANIMATEAI_QUANTITY > 1) {        
      for (let t = 0; t < AI_TYPES.length; t++) {
        if (motionTypes.some(e => e == AI_TYPES[t])) {
          motionTypes = motionTypes.reduce((memo, curr) => { if (curr != AI_TYPES[t]) { memo.push(curr); } return memo; }, [])
          const sizeMT = motionTypes.length
          for (let i = 1; i <= ANIMATEAI_QUANTITY; i++) {
            motionTypes[sizeMT + i - 1] = AI_TYPES[t] + i.toString();
          }
        }
      }
    }
  }
  return motionTypes;
};

export const makeDateRange = (startDate, endDate) => {
  const dateRange = [];
  let current = new moment(startDate);

  while (current.isBefore(endDate)) {
    dateRange.push(new moment(current).toDate());
    current = current.add(1, "day");
  }
  return dateRange;
}

export const retrieveValueFromPoseParam = (literal, key) => {
  const paramValues = literal.split("|");
  const StringPair = paramValues.find(pv => {
    const pair = pv.split("=");
    if (pair[0]===key) {
      return true;
    }
    return false;
  });

  if (StringPair) {
    return StringPair.split("=")[1];
  }

  return undefined;
}

export const retrievePosePreset = (posePresetObject) => {
  const { Params } = posePresetObject;
  const { MotionType } = posePresetObject;
  if (Params) {
    const Quantity = Number(retrieveValueFromPoseParam(Params, 'Quantity')) || 1;
    const CameraPath = retrieveValueFromPoseParam(Params, 'CameraPath');
    const Prompt = retrieveValueFromPoseParam(Params, 'Prompt');
    const MotionAmount = retrieveValueFromPoseParam(Params, 'MotionAmount');
    const MotionLength = retrieveValueFromPoseParam(Params, 'MotionLength');
    const Loop = Number(retrieveValueFromPoseParam(Params, 'Loop')) || 1;

    return { MotionType, Quantity, CameraPath, Prompt, MotionAmount, MotionLength, Loop };
  }

  return { MotionType, Params }
}