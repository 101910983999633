import {
  PROCESSING_STATUS_NEW,
  PROCESSING_STATUS_QUEUE_3D,
  PROCESSING_STATUS_SUCCESS_3D,
  PROCESSING_STATUS_FAILED_3D,
  PROCESSING_STATUS_QUEUE_BULLET,
  PROCESSING_STATUS_SUCCESS,
  PROCESSING_STATUS_FAILED_BULLET,
  PROCESSING_STATUS_ACTION_REQUIRED,
  PUBLIC_STATUS_OPTIONS
} from 'helper/constants';
import './MediaListItem.scss';
import {
  convertUTCtoLocal,
  getPublicStatusClassName,
  getStatusText,
  getEnvironment,
  download3dContent,
  downloadBullet,
  downloadSubmittedData,
  downloadMivSource,
  downloadLog
} from 'helper/utils';
import { NavLink } from 'react-router-dom';
import { Badge, Button, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateMedia } from 'redux/actions';

const MediaListItem = props => {
  const { media } = props;

  const distributionServerURL = getEnvironment().CONTENT_URL;
  const dispatch = useDispatch();

  if (!media) {
    return <></>;
  }

  const {
    processing_status,
    id,
    title,
    batch_id,
    primary_user_id,
    updated_at,
    visibility_status
  } = media;

  const onCancelSubmit = media_id => {
    const payload = {
      processing_status: PROCESSING_STATUS_FAILED_3D
    };
    dispatch(updateMedia(media_id, payload));
  };

  let statusBadge = 'primary';
  if (
    [PROCESSING_STATUS_QUEUE_3D, PROCESSING_STATUS_QUEUE_BULLET].includes(
      processing_status
    )
  ) {
    statusBadge = 'warning';
  } else if (
    [
      PROCESSING_STATUS_ACTION_REQUIRED,
      PROCESSING_STATUS_FAILED_3D,
      PROCESSING_STATUS_FAILED_BULLET
    ].includes(processing_status)
  ) {
    statusBadge = 'danger';
  } else if (processing_status === PROCESSING_STATUS_NEW) {
    statusBadge = 'info';
  } else if (
    [PROCESSING_STATUS_SUCCESS, PROCESSING_STATUS_SUCCESS_3D].includes(
      processing_status
    )
  ) {
    statusBadge = 'success';
  }

  return (
    <tr key={id} className='media-list-item'>
      <td className='thumbnail'>
        {processing_status === PROCESSING_STATUS_SUCCESS && (
          <img
            src={`${distributionServerURL}${id}/bullet/thumbnail/thumbnail.jpg`}
            width='80px'
            alt={id}
          />
        )}
      </td>
      <td className='title'>
        <NavLink to={'/media/' + id} className='link-info'>
          {title}
        </NavLink>
      </td>
      <td className='id'>
        <NavLink to={'/media/' + id} className='link-info'>
          {id}
        </NavLink>
      </td>
      <td className='batch-id'>
        <NavLink to={'/batch/' + batch_id} className='link-info'>
          {batch_id}
        </NavLink>
      </td>
      <td className='user-id'>
        <NavLink to={'/users/' + primary_user_id} className='link-info'>
          {primary_user_id}
        </NavLink>
      </td>
      <td className='updated-at'>
        <span>{convertUTCtoLocal(updated_at).format('yyyy-MM-DD HH:mm')}</span>
      </td>
      <td className='processing-status'>
        <Badge bg={statusBadge} size='sm'>
          {getStatusText(processing_status)}
        </Badge>
      </td>
      <td className='visiblity'>
        <Badge bg={getPublicStatusClassName(visibility_status)} size='sm'>
          {
            PUBLIC_STATUS_OPTIONS.find(pso => pso.value === visibility_status)
              ?.label
          }
        </Badge>
      </td>
      <td className='action'>
        {[PROCESSING_STATUS_QUEUE_3D, PROCESSING_STATUS_QUEUE_BULLET].includes(
          processing_status
        ) && (
          <Button variant='danger' size='sm' onClick={() => onCancelSubmit(id)}>
            Cancel Submission
          </Button>
        )}
        {processing_status === PROCESSING_STATUS_SUCCESS && (
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-play' size='sm'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='download-3d-video'
                onClick={() => download3dContent(media)}
              >
                Download 3D Content
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='download-bullet-video'
                onClick={() => downloadBullet(media)}
              >
                Download Bullet Video
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                eventKey='download-atlases-video'
                onClick={() => downloadSubmittedData(media)}
              >
                Download Submitted Data
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='download-miv-uploads'
                onClick={() => downloadMivSource(media)}
              >
                Download Source Views
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='download-log'
                onClick={() => downloadLog(media)}
              >
                Download Log
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {[
          PROCESSING_STATUS_FAILED_3D,
          PROCESSING_STATUS_FAILED_BULLET
        ].includes(processing_status) && (
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-play' size='sm'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='download-log'
                onClick={() => downloadLog(media)}
              >
                Download Log
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

export { MediaListItem };
