/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { actOnEC2, actOnLambda, getEc2ToolStatus, getLambdas } from 'redux/actions';

const ToolsRender = props => {
  const { allTools, allLambdas, ec2ToolsStatus } = useSelector(state => ({
    allTools: state.tools.allTools,
    allLambdas: state.tools.allLambdas,
    ec2ToolsStatus: state.ec2.ec2ToolsStatus
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLambdas());
    dispatch(getEc2ToolStatus());
  }, []);

  const onRestartLambdaTool = (id, ip, action="reboot") => {
    const payload = {
      id,
      ip,
      action
    };
    dispatch(actOnLambda(payload));
  }

  const onRestartEc2Tool = (id, ip, action="reboot") => {
    const payload = {
      id,
      ip,
      action,
      "tool": "tool"
    };
    dispatch(actOnEC2(payload));
  }

  const navigate = useNavigate();

  const toolsList = Object.values(allTools);

  toolsList.forEach(tool => {
    const assigningLambda = allLambdas.find(lambda =>
      tool.server_address.includes(lambda.ip)
    );
    if (assigningLambda) {
      tool.name = 'Lambda - ' + assigningLambda.name;
      tool.ip = assigningLambda.ip
      tool.instance_id = assigningLambda.id;
      tool.instance_type = assigningLambda.instance_type;
    }

    const assigningEC2 = ec2ToolsStatus.find(ec2 => tool.server_address.includes(ec2.ip));
    if (assigningEC2) {
      tool.name = 'EC2 - ' + assigningEC2.name;
      tool.ip = assigningEC2.ip
      tool.instance_id = assigningEC2.id;
      tool.instance_type = assigningEC2.instance_type;
    }
  });

  const columns = [
    {
      name: 'Name',
      width: '200px',
      selector: row => row.name || row.server_name,
      sortable: true
    },
    {
      name: 'Requested At',
      width: '150px',
      selector: row =>
        moment.unix(row.request_timestamp).format('MM-DD HH:mm:ss'),
      sortable: true
    },
    {
      name: 'Address',
      width: '200px',
      selector: row => row.server_address,
      sortable: true
    },
    {
      name: 'Server Type',
      width: '180px',
      selector: row => row.server_type,
      sortable: true
    },
    {
      name: 'Queue Size',
      width: '150px',
      selector: row => row.weighted_queue_size
    },
    {
      name: 'Server Status',
      width: '300px',
      cell: row => {
        if (row.status) {
          const { detail } = row.status;
          if (detail) {
            return <Badge className='bg-success'>OK</Badge>;
          }
          const { queued_tasks, running_tasks, failed_tasks } = row.status;
          return (
            <>
              <Badge className='bg-warning me-1'>
                QUEUE: {queued_tasks.length || 0}
              </Badge>
              <Badge className='bg-success me-1'>
                RUNNING: {running_tasks.length || 0}
              </Badge>
              <Badge className='bg-danger me-1'>
                FAILED: {failed_tasks.length || 0}
              </Badge>
            </>
          );
        }
        return <Badge className='bg-danger'>FAILED</Badge>;
      }
    },
    {
      name: 'Action',
      cell: row => {
        return (
          <ButtonGroup>
            <Button
              variant='info'
              size='sm'
              className='me-2 text-light'
              onClick={() => {
                window.open(row.server_address + '/queue-status', '_blank');
              }}
            >
              Get Status
            </Button>
            <Button
              variant='info'
              size='sm'
              className='me-2 text-light'
              onClick={() => {
                window.open(row.server_address + '/download-logs', '_blank');
              }}
            >
              Download Logs
            </Button>
            {row.ip && (
              <Button variant='danger' size='sm' className='me' onClick={() => {
                if (row.name.includes("EC2 - ")) {
                  onRestartEc2Tool(row.instance_id, row.ip, 'reboot')
                } else {
                  onRestartLambdaTool(row.instance_id, row.ip, 'reboot')
                }
                }}>
                Restart
              </Button>
            )}
          </ButtonGroup>
        );
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={toolsList}
      defaultSortFieldId={9}
      defaultSortAsc={false}
      pagination
      striped
      pointerOnHover
      paginationRowsPerPageOptions={[20, 40, 60]}
      paginationPerPage={20}
      onRowClicked={(row, event) => {
        if (props.EnableRowClick) {
          navigate(`/tools/${row.id}`);
        }
      }}
    />
  );
};

export { ToolsRender };
