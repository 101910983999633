/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './CustomersList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from 'redux/actions';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'ID',
    selector: row => row.id
  },
  {
    name: 'Customer_ID',
    selector: row => row.customer_id
  },
  {
    name: 'Name',
    selector: row => row.name,
    sortable: true
  },
  {
    name: 'Key',
    selector: row => row.key
  },
  {
    name: '',
    cell: (row, index, column, id) => (
      <Link to={'/statistics/customers/' + row.customer_id} className='btn btn-info text-white'>
        Statistics
      </Link>
    )
  }
];

const CustomersList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allCustomers } = useSelector(state => ({
    allCustomers: state.customers.allCustomers
  }));

  const customersList = Object.values(allCustomers);

  useEffect(() => {
    if (!customersList.length) {
      dispatch(getCustomers());
    }
  }, []);

  return (
    <div className='customers-list-page'>
      <div className='page-header'>
        <h1>Customers</h1>
        <div>
          <Link to={'/customers/new'} className='btn btn-primary'>
            New Customer
          </Link>
        </div>
      </div>
      <div className='contents'>
        <DataTable
          columns={columns}
          data={customersList}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          pagination
          striped
          pointerOnHover
          paginationRowsPerPageOptions={[20, 40, 60]}
          paginationPerPage={20}
          onRowClicked={(row, event) => {
            navigate(`/customers/${row.id}`);
          }}
        />
      </div>
    </div>
  );
};

export { CustomersList };
