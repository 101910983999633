import { actionConstants } from '../constants';
import { unset } from 'lodash';
import { successReqActionType, startReqActionType, failReqActionType } from 'helper/requestHelper';

const initialState = {
  error: null,
  submitError: '',

  allBatch: {},
  isLoadingBatch: false,

  paginatedBatches: [],
  lastEvaluatedKeys: null,
  totalCount: 0,

  isProcessRequesting: false
};

const batchReducer = (state = initialState, action) => {
  switch (action.type) {

    case startReqActionType(actionConstants.PAGINATED_LIST_BATCHES):
      return {
        ...state,
        isLoadingBatch: true
      };

    case successReqActionType(actionConstants.PAGINATED_LIST_BATCHES):
      return {
        ...state,
        isLoadingBatch: false,
        paginatedBatches: action.payload.reset ? action.payload.data : [...state.paginatedBatches, ...action.payload.data],
        lastEvaluatedKeys: action.payload.last_evaluated_key,
        totalCount: action.payload.reset ? action.payload.total_count : state.totalCount
      };
    case failReqActionType(actionConstants.PAGINATED_LIST_BATCHES):
      return {
        ...state,
        isLoadingBatch: false
      };

    case successReqActionType(actionConstants.GET_BATCH):
      return {
        ...state,
        allBatch: {
          ...state.allBatch,
          [action.payload.id]: action.payload
        }
      };
    case startReqActionType(actionConstants.PROCESS_BATCH):
      return {
        ...state,
        isProcessRequesting: true,
      }
    case successReqActionType(actionConstants.PROCESS_BATCH):
      return {
        ...state,
        isProcessRequesting: false
      };
    case failReqActionType(actionConstants.PROCESS_BATCH):
      return {
        ...state,
        isProcessRequesting: false
      };
    case successReqActionType(actionConstants.DELETE_BATCH):
      const newBatch = { ...state.allBatch }
      unset(newBatch(action.payload.batchId))
      return {
        ...state,
        allBatch: newBatch
      };
    case successReqActionType(actionConstants.UPDATE_BATCH):
      return {
        ...state,
        allBatch: {
          ...state.allBatch,
          [action.payload.id]: {
            ...state.allBatch[action.payload.id],
            ...action.payload
          }
        }
      };
    case actionConstants.RESET_ERROR_MESSAGE:
      return {
        ...state,
        submitError: ''
      };
    default:
      return state;
  }
};

export default batchReducer;
