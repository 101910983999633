import { actionConstants } from '../constants';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  isLoadingStatistics: false,

  userStatistics: [],
  customerStatistics: [],
  error: null
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        userStatistics: action.payload
      };

    case startReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        customerStatistics: action.payload
      };
    
    default:
      return state;
  }
};

export default statisticsReducer;
