import { Card } from 'react-bootstrap';
import './DisplayCardItem.scss';

const DisplayCardItem = props => {
  const { title, value, onClick = () => {} } = props;

  let bg = 'info';
  if (value < 5) {
    bg = 'success';
  } else if (value < 10) {
    bg = 'warning';
  } else {
    bg = 'danger';
  }
  return (
    <Card
      className='display-card-item'
      bg={bg}
      text='light'
      onClick={onClick}
    >
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <p>{value}</p>
      </Card.Body>
    </Card>
  );
};

export { DisplayCardItem };
