import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Login } from './containers/Authentication/Login';
import { Hub } from 'aws-amplify';
import { Container } from 'react-bootstrap';
import Sidebar from './components/Sidebar';
import './App.scss';
import { MediaList } from './containers/MediaList';
import { MediaDetail } from 'containers/MediaDetail';
import { UsersList } from 'containers/UsersList';
import { UserDetailPage } from 'containers/UserDetailPage';
import { NotificationContainer } from 'react-notifications';
import { GrabData } from 'components/GrabData';
import { StatusBar } from 'components/StatusBar';
import { BatchList } from 'containers/BatchList';
import { BatchDetail } from 'containers/BatchDetail';
import { CustomersList } from 'containers/CustomersList/CustomersList';
import { CustomerDetailPage } from 'containers/CustomerDetailPage/CustomerDetailPage';
import { CustomerStatistics } from 'containers/CustomerStatistics';
import { ToolsList } from 'containers/ToolsList';
import { ToolDetailPage } from 'containers/ToolDetailPage';
import { DashboardPage } from 'containers/DashboardPage/DashboardPage';
import { UserStatistics } from 'containers/UserStatistics';

const App = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const getAuthInfo = () => {
    const getCurrentSession = async () => {
      const currentSession = await Auth.currentSession();
      const userGroup =
        currentSession.getAccessToken().payload['cognito:groups'];
      if (userGroup.includes('Admin')) {
        return currentSession;
      }
      await Auth.signOut();
      return null;
    };

    getCurrentSession()
      .then(session => {
        const accessToken = session.getAccessToken().getJwtToken();
        setAccessToken(accessToken);

        const userName = Auth.user ? Auth.user.username : '';
        if (userName) {
          localStorage.setItem('@userName', userName);
          localStorage.setItem('@accessToken', accessToken);
        }
        const userAttribute = Auth.user ? Auth.user.attributes : null;
        if (userAttribute) {
          localStorage.setItem('@userAttribute', JSON.stringify(userAttribute));
        }
      })
      .catch(() => {
        setAccessToken(null);
      });
  };
  const listener = data => {
    switch (data.payload.event) {
      case 'signIn':
        getAuthInfo();
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signOut':
        console.log('user signed out');
        getAuthInfo();
        break;
      case 'signIn_failure':
        console.error('user sign in failed');
        break;
      case 'tokenRefresh':
        console.log('token refresh succeeded');
        getAuthInfo();
        break;
      case 'tokenRefresh_failure':
        console.error('token refresh failed');
        break;
      case 'configured':
        console.log('the Auth module is configured');
        break;
      default:
        console.log(data.payload.event);
    }
  };
  Hub.listen('auth', listener);

  useEffect(() => {
    getAuthInfo();
  }, []);

  if (accessToken) {
    return (
      <Router>
        <Container fluid className='main-container'>
          <div className='layout-wrapper'>
            <div className='sidebar-wrapper'>
              <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
            </div>
            <div
              className={`page-content-wrapper ${collapsed ? 'collapsed' : ''}`}
            >
              <div className='page-content-body'>
                <StatusBar />
                <GrabData />
                <Routes>
                  <Route exact path='/' element={<DashboardPage />} />
                  <Route exact path='/media' element={<MediaList />} />
                  <Route
                    exact
                    path='/media/:media_id'
                    element={<MediaDetail />}
                  />
                  <Route exact path='/batch' element={<BatchList />} />
                  <Route
                    exact
                    path='/batch/:batch_id'
                    element={<BatchDetail />}
                  />
                  <Route path='/customers' element={<CustomersList />} />
                  <Route
                    path='/customers/:customer_id'
                    element={<CustomerDetailPage />}
                  />
                  <Route path='/tools' element={<ToolsList />} />
                  <Route
                    path='/tools/:tool_id'
                    element={<ToolDetailPage />}
                  />
                  <Route path='/users' element={<UsersList />} />
                  <Route
                    path='/users/:user_id'
                    element={<UserDetailPage />}
                  />
                  <Route exact path='/statistics/users/:user_id' element={<UserStatistics />} />
                  <Route exact path='/statistics/customers/:customer_id' element={<CustomerStatistics />} />
                </Routes>
              </div>
            </div>
          </div>
          <NotificationContainer />
        </Container>
      </Router>
    );
  }
  return <Login />;
};

export default App;
