/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  Badge,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { actOnEC2 } from 'redux/actions';

const EC2Render = props => {
  const { ec2Status } = useSelector(state => ({
    ec2Status: state.ec2.ec2Status
  }));

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'ID',
      selector: row => row.id
    },
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'IP',
      width: '160px',
      selector: row => row.ip,
      sortable: true
    },
    {
      name: 'Instance Type',
      width: '150px',
      selector: row => row.instance_type,
      sortable: true
    },
    {
      name: 'Status',
      width: '100px',
      selector: row => row.state['Name']
    },
    {
      name: 'Running Medias',
      cell: (row, index, column, id) => (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              {row.running_medias && Array.isArray(row.running_medias)
                ? row.running_medias.map(mediaId => (
                    <p key={mediaId}>{mediaId}</p>
                  ))
                : ''}
            </Tooltip>
          }
        >
          <Badge className='bg-success'>
            {row.running_medias ? row.running_medias.length : 0}
          </Badge>
        </OverlayTrigger>
      )
    },
    {
      name: 'Action',
      width: '150px',
      cell: row => (
        <ButtonGroup className='ec2-button-group' size='sm'>
          {row.state['Name'] === 'stopped' && (
            <Button
              variant='primary'
              onClick={() => onAction(row.id, row.ip, 'start')}
            >
              Start
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='warning'
              onClick={() => onAction(row.id, row.ip, 'reboot')}
            >
              Reboot
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='danger'
              onClick={() => onAction(row.id, row.ip, 'stop')}
            >
              Stop
            </Button>
          )}
        </ButtonGroup>
      )
    }
  ];

  const onAction = (id, ip, action) => {
    const payload = {
      id,
      ip,
      action
    };
    dispatch(actOnEC2(payload));
  };

  return (
    <DataTable
      columns={columns}
      data={ec2Status}
      defaultSortFieldId={9}
      defaultSortAsc={false}
      pagination
      striped
      pointerOnHover
      paginationRowsPerPageOptions={[20, 40, 60]}
      paginationPerPage={20}
    />
  );
};

export { EC2Render };
