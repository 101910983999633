/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Badge
} from 'react-bootstrap';
import './UsersList.scss';
import { useDispatch, connect } from 'react-redux';
import { getUsersByGroup, getVimmerseUsers } from 'redux/actions';
import { convertUTCtoLocal, getAttributeFromUser } from 'helper/utils';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
// import { CSVLink } from 'react-csv';

const columns = [
  {
    name: 'Username',
    selector: row => row.Username,
    sortable: true
  },
  {
    name: 'Handle',
    selector: row => {
      const accountName = getAttributeFromUser(row, 'preferred_username');
      return accountName;
    },
    sortable: true
  },
  {
    name: 'Name',
    selector: row => {
      return `${getAttributeFromUser(row, 'given_name')} ${getAttributeFromUser(
        row,
        'family_name'
      )}`;
    },
    width: '200px',
    sortable: true
  },
  {
    name: 'Email',
    selector: row => {
      return getAttributeFromUser(row, 'email');
    },
    sortable: true
  },
  {
    name: 'Email Verified',
    selector: row => {
      return getAttributeFromUser(row, 'email_verified');
    },
    sortable: true
  },
  {
    name: 'Credit',
    selector: row => getAttributeFromUser(row, 'custom:credit'),
    width: '100px',
    sortable: true
  },
  {
    name: 'Created At',
    selector: row =>
      convertUTCtoLocal(row.UserCreateDate).format('YYYY-MM-DD HH:mm:ss'),
    format: (row, index) => convertUTCtoLocal(row.UserCreateDate).format('ll'),
    width: '120px',
    sortable: true
  },
  {
    name: 'Group',
    width: '100px',
    cell: (row, index, column, id) => (
      <div className='width-auto'>
        <Badge className='developer-badge' bg='primary'>
          Developer
        </Badge>
        <Badge className='staff-badge' bg='info'>
          Staff
        </Badge>
      </div>
    )
  },
  {
    name: '',
    cell: (row, index, column, id) => (
      <Link to={'/statistics/users/' + row.Username} className='btn btn-info text-white'>
        Statistics
      </Link>
    )
  }
];

const userDeveloperOptions = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Developer' },
  { value: 2, label: 'Paid User' }
];

const UsersList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const [userDeveloper, setuserDeveloper] = useState(userDeveloperOptions[0]);

  const usersList = Object.values(props.allUsers);

  const developerList = Object.values(props.Developer);
  const staffList = Object.values(props.Staff);

  const developerUsernames = developerList.map(s => s.Username);
  const staffUserNames = staffList.map(s => s.Username);

  const onReloadUsers = () => {
    dispatch(getVimmerseUsers());
    dispatch(getUsersByGroup('Developer'));
    dispatch(getUsersByGroup('Staff'));
  };

  useEffect(() => {
    if (!usersList.length) {
      onReloadUsers();
    }
  }, []);

  const onUpdateKeyword = event => {
    setSearchKey(event.target.value);
  };

  const rowStyle = [
    {
      when: row => developerUsernames.includes(row.Username),
      classNames: ['developer-user']
    },
    {
      when: row => staffUserNames.includes(row.Username),
      classNames: ['staff-user']
    },
    {
      when: row => !row.Enabled,
      classNames: ['bg-secondary']
    }
  ];

  let filtered = usersList;

  if (searchKey) {
    filtered = usersList.filter(usr => {
      const givenName = getAttributeFromUser(usr, 'given_name');
      const email = getAttributeFromUser(usr, 'email');

      if (
        (givenName &&
          givenName.toLowerCase().includes(searchKey.toLowerCase())) ||
        (email && email.toLowerCase().includes(searchKey.toLowerCase())) ||
        usr.Username.toLowerCase().includes(searchKey.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  if (userDeveloper.value === 1) {
    filtered = filtered.filter(usr =>
      developerUsernames.includes(usr.Username)
    );
  } else if (userDeveloper.value === 2) {
    filtered = filtered.filter(
      usr => !developerUsernames.includes(usr.Username)
    );
  }

  filtered = filtered.sort((a, b) =>
    a.UserCreateDate > b.UserAttributes ? -1 : 1
  );

  // Insert content created
  // filtered.forEach(user => {
  //   const userMedia = mediaList.filter(
  //     c => c.primary_user_id === user.Username
  //   );
  //   user.media_created = userMedia.length;
  // });

  // const reportingData = filtered.map(u => {
  //   return {
  //     Username: u.Username,
  //     handle: getAttributeFromUser(u, 'preferred_username'),
  //     is_developer: developerUsernames.includes(u.Username),
  //     given_name: getAttributeFromUser(u, 'given_name'),
  //     family_name: getAttributeFromUser(u, 'family_name'),
  //     name: `${getAttributeFromUser(u, 'given_name')} ${getAttributeFromUser(
  //       u,
  //       'family_name'
  //     )}`,
  //     email: getAttributeFromUser(u, 'email'),
  //     email_verified: getAttributeFromUser(u, 'email_verified'),
  //     credits: getAttributeFromUser(u, 'custom:credit'),
  //     created_at: convertUTCtoLocal(u.UserCreateDate).format('ll'),
  //     contents_created: u.media_created
  //   };
  // });

  // const csvHeaders = [
  //   { label: 'Username', key: 'Username' },
  //   { label: 'Handle', key: 'handle' },
  //   { label: 'First Name', key: 'given_name' },
  //   { label: 'Last Name', key: 'family_name' },
  //   { label: 'Full Name', key: 'name' },
  //   { label: 'Email', key: 'email' },
  //   { label: 'Email Verified', key: 'email_verified' },
  //   { label: 'Subscription', key: 'tier' },
  //   { label: 'Credits', key: 'credits' },
  //   { label: 'Expire at', key: 'expire_at' },
  //   { label: 'Member Since', key: 'created_at' },
  //   { label: '# of Contents', key: 'contents_created' },
  //   { label: 'Developer?', key: 'is_developer' }
  // ];

  // const csvReport = {
  //   data: reportingData,
  //   headers: csvHeaders,
  //   filename: 'Vimmerse Users.csv'
  // };

  return (
    <div className='users-list-page'>
      <div className='page-header'>
        <h1>Users</h1>
        <div>
          {/* <CSVLink {...csvReport} className='btn btn-secondary me-1'>
            <strong>Download by CSV</strong>
          </CSVLink> */}
          <Button variant='primary' className='me-1' onClick={onReloadUsers}>
            Reload
          </Button>
          <Link to={'/users/new'} className='btn btn-primary'>
            New User
          </Link>
        </div>
      </div>
      <div className='row page-tools'>
        <div className='col-md-3'>
          <FormGroup>
            <FormLabel>Keyword</FormLabel>
            <FormControl
              type='text'
              value={searchKey}
              placeholder='Please input keyword to search'
              onChange={onUpdateKeyword}
            />
          </FormGroup>
        </div>
        <div className='col-md-3'>
          <FormGroup>
            <FormLabel>Show Users</FormLabel>
            <Select
              value={userDeveloper}
              options={userDeveloperOptions}
              onChange={setuserDeveloper}
            />
          </FormGroup>
        </div>
      </div>
      <div className='contents'>
        <DataTable
          columns={columns}
          data={filtered}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          pagination
          striped
          pointerOnHover
          paginationRowsPerPageOptions={[20, 40, 60]}
          paginationPerPage={40}
          conditionalRowStyles={rowStyle}
          onRowClicked={(row, event) => {
            navigate(`/users/${row.Username}`);
          }}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  const { allUsers, Developer, Staff } = users;
  return {
    allUsers,
    Developer,
    Staff
  };
}

const connectedObject = connect(mapStateToProps)(UsersList);
export { connectedObject as UsersList };
