import { actionConstants } from 'redux/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';

export const getEc2Status = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_EC2_STATUS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/ec2/status`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_EC2_STATUS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_EC2_STATUS) });
  }
};

export const getEc2ToolStatus = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_EC2_TOOLS_STATUS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/ec2/tools-status`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_EC2_TOOLS_STATUS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_EC2_TOOLS_STATUS) });
  }
};

export const actOnEC2 = (payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ACT_ON_EC2) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}admin/ec2/action`;

  try {
    const response = await fetch(url, requestOptions);
    await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.ACT_ON_EC2),
      payload: {}
    });
    NotificationManager.success(
      'We have submitted your request successfully',
      'Success'
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.ACT_ON_EC2),
      error: error.message
    });
    NotificationManager.error(
      'Please wait until all processes are finished.',
      'Failed'
    );
  }
};