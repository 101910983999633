import { actionConstants } from 'redux/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';

export const getTools = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_TOOLS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/tools`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_TOOLS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_TOOLS) });
  }
};

export const addTool = (payload, callback) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ADD_TOOL) });
  try {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/tools`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.ADD_TOOL),
      payload: retData
    });
    if (callback) {
      callback(retData.id);
    }
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.ADD_TOOL) });
  }
};

export const updateTool = (id, payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_TOOL) });
  try {
    const requestOptions = {
      method: 'put',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/tools/${id}`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_TOOL),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_TOOL) });
  }
};

export const deleteTool = id => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.REMOVE_TOOL) });
  try {
    const requestOptions = {
      method: 'delete',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/tools/${id}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.REMOVE_TOOL),
      payload: { id }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.REMOVE_TOOL) });
  }
};


export const getLambdas = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_LAMBDAS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/lambdas/instances`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_LAMBDAS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_LAMBDAS) });
  }
};

export const actOnLambda = (payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ACT_ON_LAMBDA) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}admin/lambdas/action`;

  try {
    const response = await fetch(url, requestOptions);
    await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.ACT_ON_LAMBDA),
      payload: {}
    });
    NotificationManager.success(
      'We have submitted your request successfully',
      'Success'
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.ACT_ON_LAMBDA),
      error: error.message
    });
    NotificationManager.error(
      'Please wait until all tasks are finished.',
      'Failed'
    );
  }
};
