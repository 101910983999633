import { useState, useEffect } from 'react';
import { Button, Modal, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import './CopyContentDialog.scss';
import { ENV_OPTIONS } from 'helper/constants';
import { useDispatch } from 'react-redux';
import { copyMedia } from 'redux/actions';

const copyAsOptions = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' }
];

const CopyContentDialog = props => {
  const { onClose, id } = props;

  const [env, setEnv] = useState(ENV_OPTIONS[0]);
  const [newEnv, setNewEnv] = useState(ENV_OPTIONS[0]);
  const [newOwner, setNewOwner] = useState('');

  const [copySameId, setCopySameId] = useState(copyAsOptions[0]);

  const dispatch = useDispatch();

  useEffect(() => {
    const envString = localStorage.getItem('myEnv');
    if (envString) {
      const envValue = JSON.parse(envString);
      setEnv(envValue);
    }
  }, []);

  useEffect(() => {
    if (env.value === newEnv.value) {
      setCopySameId(copyAsOptions[1]);
    }
  }, [env, newEnv]);

  const submitModal = () => {
    if (newOwner) {
      const payload = {
        env: env.value,
        new_env: newEnv.value,
        media_id: id,
        new_username: newOwner,
        copy_same_id: copySameId.value
      };
      dispatch(copyMedia(payload));
      onClose();
    }
  };

  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='md'>
      <Modal.Header>Copy Media</Modal.Header>
      <Modal.Body>
        <FormGroup className='mb-3'>
          <FormLabel>Current Environment</FormLabel>
          <Select options={ENV_OPTIONS} isDisabled value={env} />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Destination Environment</FormLabel>
          <Select options={ENV_OPTIONS} value={newEnv} onChange={setNewEnv} />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>New Owner</FormLabel>
          <FormControl
            value={newOwner}
            onChange={(e) => setNewOwner(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Copy Same ID?</FormLabel>
          <Select
            isDisabled={newEnv.value === env.value}
            options={copyAsOptions}
            value={copySameId}
            onChange={setCopySameId}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={submitModal}>
          Confirm
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { CopyContentDialog };
