/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import './DashboardPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getEc2Status, getQueueSizes, getTools } from 'redux/actions';
import { Badge, Button } from 'react-bootstrap';
import { ToolsRender } from './ToolsRender';
import { getEnvironment } from 'helper/utils';
import { SQSRender } from './SQSRender';
import { EC2Render } from './EC2Render';

const DashboardPage = props => {
  const dispatch = useDispatch();

  const { isLoadingTools, isLoadingQueueSizes, isLoadingEC2Status } = useSelector(state => ({
    isLoadingTools: state.tools.isLoadingTools,
    isLoadingQueueSizes: state.queue.isLoadingQueueSizes,
    isLoadingEC2Status: state.ec2.isLoadingEC2Status,
  }));

  const isLoading = isLoadingTools || isLoadingQueueSizes || isLoadingEC2Status;

  useEffect(() => {
    onRefresh();
    const intervalCall = setInterval(() => {
      onRefresh();
    }, 60000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  const onRefresh = () => {
    if (!isLoadingTools) {
      dispatch(getTools());
    }

    if (!isLoadingQueueSizes) {
      dispatch(getQueueSizes());
    }
    
    if (!isLoadingEC2Status) {
      dispatch(getEc2Status());
    }
  };

  const environmentName = getEnvironment().ENVIRONMENT_NAME;

  return (
    <div className='dashboard-page'>
      <div className='page-header'>
        <h1>
          Dashboard <Badge bg='danger'>{environmentName}</Badge>
        </h1>
        <div>
          <Button
            variant='primary'
            className='me-2'
            onClick={onRefresh}
            disabled={isLoading}
          >
            <i className={`fas fa-redo ${isLoading ? 'fa-spin' : ''}`} />{' '}
            {!isLoading ? 'Refresh' : 'Loading'}
          </Button>
        </div>
      </div>
      <div className='contents'>
        <div className='row'>
          <div className='col-lg-3'>
            <h3>
              SQS{' '}
              {isLoadingQueueSizes && (
                <Badge bg='info'>
                  <i className='fas fa-redo fa-spin' /> Loading
                </Badge>
              )}
            </h3>
            <SQSRender />
          </div>
          <div className='col-lg-9'>
            <h3>
              EC2{' '}
              {isLoadingEC2Status && (
                <Badge bg='info'>
                  <i className='fas fa-redo fa-spin' /> Loading
                </Badge>
              )}
            </h3>
            <EC2Render />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h3>
              Tool Status{' '}
              {isLoadingTools && (
                <Badge bg='info'>
                  <i className='fas fa-redo fa-spin' /> Loading
                </Badge>
              )}
            </h3>

            <ToolsRender />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardPage };
