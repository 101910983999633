import { DisplayCardItem } from "components/DisplayCardItem";
import { useDispatch, useSelector } from "react-redux";
import { getQueueSizes } from "redux/actions";

const TITLES_DICTIONARY = {
    approximate_number_of_messages: "# of Messages",
    approximate_number_of_messages_delayed: "Messages Delayed"
} 

const QueueSizeRender = (props) => {
    const { type, detail } = props;
    const dispatch = useDispatch();
    const { queueSizes, isLoadingQueueSizes } = useSelector(state => ({
        isLoadingQueueSizes: state.queue.isLoadingQueueSizes,
        queueSizes: state.queue.queueSizes
    }));

    const onClickItem = () => {
        if (!isLoadingQueueSizes) {
            dispatch(getQueueSizes());
        }
    }
    
    return (
        <DisplayCardItem
            title={TITLES_DICTIONARY[detail] || ' '}
            value={queueSizes[type] ? queueSizes[type][detail] : ' '}
            onClick={onClickItem}
        />
    );
}

export { QueueSizeRender };